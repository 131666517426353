<template>
  <div class="myyezai">
    <div class="page">
      <!-- 会员宣传图 -->
      <div class="banner">
        <img
          src="../../assets/images/message/banner_b.png"
          alt=""
          style="width: 1200px; cursor: pointer; border-radius: 2px"
          @click="hy"
        />
      </div>
      <!-- 个人资料 -->
      <div class="m-mail">
        <div class="m-mail-data">
          <div class="m-mail-data-top">
            <div class="m-mail-data-top-img">
              <img :src="avatar || src" alt="" @click="xcsb" />
              <p v-if="avatarAppStatus === '2'">审核中</p>
            </div>
            <!-- 跳转 -->
            <div class="data-top-right">
              <div class="data-NT">
                <p class="data-name" @click="pao(2)">{{ nickName }}</p>
                <img
                  :src="iSmember == 0 ? huiyuan : Fhuiyuan"
                  alt=""
                  @click="hy"
                />
                <img
                  :src="realAuthStatus == 2 ? Frenzheng : renzheng"
                  alt=""
                  @click="rzsb"
                />
              </div>
              <div class="data-id">
                ID:&nbsp;<span>{{ idcard }}</span>
              </div>
              <!-- 百分比 -->
              <div class="data-ZB">
                <div class="progress" style="width: 130px">
                  <el-progress
                    :percentage="Percentage"
                    :format="format"
                  ></el-progress>
                </div>
                <div class="data-ZB-zi">
                  <span class="ZB-zi-bfb">{{ format() }}</span>
                  <span class="ZB-zi-a" @click="pao(1)">完善资料</span>
                  <span class="ZB-zi-a" @click="pao(2)">预览</span>
                </div>
              </div>
            </div>
          </div>
          <div class="m-mail-data-bottom">
            <div class="bottom-box1">
              <div class="num">{{ number }}</div>
              <div class="bottom-a">
                <span>照片</span><span @click="jump(1)">&nbsp;上传</span>
              </div>
            </div>
            <div class="bottom-box2">
              <div class="num">0</div>
              <div class="bottom-a">
                <span>也在币</span>
                <!-- <span @click="jump(2)">&nbsp;充值 </span> -->
                <span></span>
              </div>
            </div>
          </div>
        </div>
        <!-- 样式修改消息提示() -->
        <div class="m-mail-right">
          <div class="m-mail-eyes hov" @click="jumpL(1)">
            <div class="eyes-top">
              <img
                class="boots"
                src="../../assets/images/index/liulan.png"
                alt=""
              />
            </div>
            <div>
              <span class="fly">{{ ok.broswseCount }} 人</span
              ><span>刚刚看过我</span>
            </div>
          </div>
          <div class="m-mail-emil hov" @click="jumpL(2)">
            <div class="emil-top">
              <img src="../../assets/images/index/yezai_mail.png" alt="" />
            </div>
            <div>
              <!-- <span class="fly">{{ MkeessCont }}条</span><span>未读消息</span> -->
              <span class="fly">{{ meessCont }}条</span><span>未读消息</span>
            </div>
          </div>
          <div class="m-mail-me hov" @click="jumpL(3)">
            <div class="me-top">
              <img src="../../assets/images/index/yezai_xin.png" alt="" />
            </div>

            <div>
              <span class="fly">{{ ok.followCount }}人</span><span>关注我</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 相亲 -->
      <div class="m-details">
        <div class="advertisement">
          <div class="advertisement-service">
            <h3>也在服务</h3>
            <ul>
              <li @click="jumpZ(1)">
                <img src="../../assets/images/index/huiyuan.png" alt="" />
                <div>
                  <h4>也在会员</h4>
                  <p>解锁收信/发信，更多精准筛选 条件</p>
                </div>
              </li>
              <li @click="jumpZ(1)">
                <img src="../../assets/images/index/start.png" alt="" />
                <div>
                  <h4>也在特权</h4>
                  <p>查看谁赞了我，谁关注我，搜索 排名优先，更多展示机会</p>
                </div>
              </li>
              <li @click="jumpZ(2)">
                <img src="../../assets/images/index/gold.png" alt="" />
                <div>
                  <h4>也在币</h4>
                  <p>也在网专用虚拟货币，可以购买 各种自助服务！</p>
                </div>
              </li>
              <li @click="jumpZ(3)">
                <img src="../../assets/images/index/store.png" alt="" />
                <div>
                  <h4>直营门店</h4>
                  <p>优质精选会员，红娘服务一对 一，五步服务，六重保障！</p>
                </div>
              </li>
            </ul>
          </div>
          <div class="advertisement-relevant">
            <h3>也在相关</h3>
            <div class="line"></div>
            <div>
              <p>也在红娘如果联系您，会使用如下号码：</p>
              <p>佛山顺德：0757-22668180</p>
              <p>佛山禅城：0757-22668085</p>
            </div>
          </div>
          <div class="advertisement-app1" @click="JJum">
            <div>网络举报APP下载</div>
            <p>清理网络空间 &nbsp; 维护网民权益</p>
          </div>
          <div class="advertisement-app2" @click="JJum">
            <div>国家反诈中心APP正式上线</div>
            <p class="jc">警民携手 齐心反诈</p>
            <p>诈骗预警 报案助手 我要举报 风险查询</p>
          </div>
        </div>

        <div class="details-user">
          <div class="user-top">
            <div class="user-top-title">每日推荐</div>
          </div>
          <!-- 修改内容 -->
          <!-- <div class="cityShow" v-show="cityShow">
            <div class="city-change">
              
              <div class="information">
                <div class="city-btn" @click="cShow(1)">保存</div>
                <div class="city-btn cancel" @click="cancel()">取消</div>
              </div>
            </div>
          </div> -->
          <!-- 列表每日推荐 -->
          <div class="user-bottom">
            <div
              class="user"
              v-for="item in arry2"
              :key="item.id"
              @click="info(item)"
            >
              <div class="image">
                <img class="img-object" :src="item.htImg" alt="" />
              </div>
              <div class="missing">
                <div class="missing-name">
                  <!-- 昵称 -->
                  <p class="missing1">{{ item.nickName }}</p>

                  <img
                    v-if="item.isVipMember == 1"
                    :src="item.isVipMember == 1 ? `${Fhuiyuan}` : ''"
                    alt=""
                  />
                  <img
                    v-if="item.realAuthStatus == 2"
                    :src="item.realAuthStatus == 2 ? `${Frenzheng}` : ''"
                    alt=""
                  />
                </div>
                <!-- 信息 -->
                <p class="personal">
                  <span v-if="item.age">{{ item.age }}岁</span>
                  <span v-if="item.workAddrCityName">
                    | {{ item.workAddrCityName }}</span
                  >
                  <span v-if="item.height"> | {{ item.height }}cm</span>
                  <span v-if="item.education"> | {{ item.education }}</span>
                </p>
                <!-- 内心独白  -->

                <!-- 男士：比自己年龄大三岁小七岁     女士：比自己小三岁大七岁 -->
                <!-- 我正在寻找辽宁沈阳,身高在158-200cm,月薪在12000-50000元的男性 -->
                <!-- 男士：比自己年龄大三岁小七岁     女士：比自己小三岁大七岁 身高在158-200cm,-->
                <p class="intr">
                  <span class="ni">{{ item.introduce }}</span>
                </p>
                <div class="btn" @click.stop="renshi(item)" v-show="item.show">
                  相互认识一下
                  <img
                    src="../../assets/images/info/xin_aaL.png"
                    class="btn-aaL"
                    alt=""
                  />
                  <!-- 未悬浮 -->
                  <img
                    src="../../assets/images/info/xin_aaLL.png"
                    class="btn-aa"
                    alt=""
                  />
                  <!-- 悬浮 -->
                </div>
                <div class="btn-a" v-show="!item.show">
                  已打招呼
                  <img src="../../assets/images/info/xin_aaLL.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 推荐 -->
    <div>{{ text() }}</div>
    <div class="m-top">
      <div class="m-top-title">
        <!--彩蛋双击打开 <p style="cursor: pointer" v-longpress="im"> @click="im" -->
        <p style="cursor: pointer">想查看更多符合条件的客户？尝试一下搜索吧</p>
        <div class="m-top-title_btn" @click="Sump">
          <span>点击前往搜索</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { infocur, infoPerfection } from "@/api/login/login.js";
import { msgTotal, subTk } from "@/api/member/member.js";
import { InforRecommend } from "@/api/member/member.js";
import local from "@/api/common/local.js";
import { ossL } from "@/api/common/oss/ossL.js";
import { loveLove } from "@/api/my/my.js";
import { mapState } from "vuex"; //mapMutations
import DB from "@/assets/js/Greeting.js";
import { allAppStatus } from "@/api/member/member.js";

export default {
  data() {
    return {
      //默认头像
      src: require("@/assets/images/message/tou.png"),
      ManSrc: require("@/assets/images/message/manTou.png"),
      WomanSrc: require("@/assets/images/message/womanTou.png"),
      isAvatar: "",
      sex: 0,
      huiyuan: require("@/assets/images/tubiao/yezai_memberQ.png"),
      Fhuiyuan: require("@/assets/images/tubiao/yezai_member.png"),
      renzheng: require("@/assets/images/tubiao/yezai_auth_b.png"),
      Frenzheng: require("@/assets/images/tubiao/yezai_auth_bb.png"),
      avatar: "",
      idcard: "",
      nickName: "",
      city: "",
      ok: {
        broswseCount: 0,
        followCount: 0,
      },
      i: -1,
      resultlist: [],
      arry2: [],
      educations: [
        {
          value: "高中及以下",
          label: "0",
        },
        {
          value: "中专",
          label: "1",
        },
        {
          value: "大专",
          label: "2",
        },
        {
          value: "大学本科",
          label: "3",
        },
        {
          value: "硕士",
          label: "4",
        },
        {
          value: "博士",
          label: "5",
        },
      ],
      iSmember: "",
      realAuthStatus: "",
      Percentage: 0,
      number: 0,
      HYmess: [],
      avatarAppStatus: "3",
      Num: 0,
      // MkeessCont: 0,
      /**
       * 择偶
       */
      memberMateSelectionAge: "",
      memberMateSelectionHeight: "",
      memberMateSelectionMonthIncome: "",
    };
  },
  created() {
    this.canshu();
    this.infoPerfectionLL();
    this.$store.state.index = 0;
  },
  mounted() {
    //阻止浏览器后退
    history.pushState(null, null, document.URL);
    window.onpopstate = function() {
      history.pushState(null, null, document.URL);
    };
    this.$nextTick(() => {
      // this.initScroll()
      window.onscroll = function() {
        //变量scrollTop是滚动条滚动时，距离顶部的距离
        var scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        // scrollTop = scrollTop  - 500
        // console.log(scrollTop);
        //变量windowHeight是可视区的高度
        var windowHeight =
          document.documentElement.clientHeight || document.body.clientHeight;

        //变量scrollHeight是滚动条的总高度
        var scrollHeight =
          document.documentElement.scrollHeight || document.body.scrollHeight;
        // console.log('__',scrollTop);
        // console.log('__',windowHeight );
        // console.log('__',scrollHeight = scrollHeight - 400);
        //滚动条到底部的条件
        if (scrollTop + windowHeight >= scrollHeight) {
          //写后台加载数据的函数
          console.log(
            "距顶部" +
              scrollTop +
              "可视区高度" +
              windowHeight +
              "滚动条总高度" +
              scrollHeight
          );
        }
      };
    });
    // window.addEventListener("scroll", this.handleScroll);
  },
  computed: mapState(["meessCont"]),
  methods: {
    text() {
      console.log("__===000000_");
    },
    //测试函数start
    im() {
      console.log("数据u");
    },
    //测试函数end
    // ...mapMutations(["Mkeess"]),
    handleScroll() {},
    //ceshi
    xcsb() {
      let rel = this.$router.resolve({
        path: "/n/user/_album",
      });
      window.open(rel.href, "_blank");
    },
    rzsb() {
      let rel = this.$router.resolve({
        path: "/n/user/_auth",
      });
      window.open(rel.href, "_blank");
    },
    JJum() {
      window.open("https://www.12377.cn/node_548446.htm", "_blank");
    },
    Sump() {
      let rel = this.$router.resolve({
        path: "/n/search",
      });
      window.open(rel.href, "_blank");
    },

    login() {
      console.log("this.", this.idcard);
      const _that = this;
      //-------------
      var options = {
        user: this.idcard,
        pwd: "yezai_user@easemob",
        // appKey: _that.WebIM.config.appkey,
        appKey: _that.$WebIM.config.appkey,
        success: function() {
          console.log("登录成功");
        },
        error: function() {},
      };
      // _that.WebIM.conn.open(options);
      _that.$WebIM.conn.open(options);

      _that.$WebIM.conn.listen({
        onOpened: function(message) {
          // localStorage.setItem("HUser", "1234");
          console.log("00", message);
          // _that.liao();
        },
        onTextMessage: function(message) {
          console.log("onTextMessage", message);
          // _that.liao();
        },
      });
    },
    /**
     * 会话列表
     */
    // liao() {
    //   // /**
    //   //  * @param {String|Array} users - 用户id
    //   //  */
    //   this.HYmess = [];
    //   this.$WebIM.conn.getSessionList().then((res) => {
    //     const data = res.data.channel_infos;
    //     //遍历处理数据
    //     data.map((i) => {
    //       // 也在小助手
    //       if (
    //         Number(
    //           i.channel_id.substring(
    //             i.channel_id.indexOf("_") + 1,
    //             i.channel_id.indexOf("@")
    //           )
    //         ) === 10000
    //       ) {
    //         // this.hyzs = {
    //         //   channel_id: i.channel_id.substring(
    //         //     i.channel_id.indexOf("_") + 1,
    //         //     i.channel_id.indexOf("@")
    //         //   ),
    //         //   payload: JSON.parse(i.meta.payload).bodies[0].msg,
    //         //   timestamp: i.meta.timestamp,
    //         //   unread_num: i.unread_num,
    //         // };
    //         console.log("001");
    //       } else if (
    //         i.channel_id.substring(
    //           i.channel_id.indexOf("_") + 1,
    //           i.channel_id.indexOf("@")
    //         ) == ""
    //       ) {
    //         console.log("001");
    //       } else {
    //         this.HYmess.push({
    //           channel_id: i.channel_id.substring(
    //             i.channel_id.indexOf("_") + 1,
    //             i.channel_id.indexOf("@")
    //           ),
    //           payload: JSON.parse(i.meta.payload).bodies[0].msg,
    //           timestamp: i.meta.timestamp,
    //           unread_num: i.unread_num,
    //         });
    //       }
    //     });
    //     console.log("数据", this.HYmess);
    //     // let s = 0;
    //     for (let i = 0; i < this.HYmess.length; i++) {
    //       console.log("消息", this.HYmess[i].unread_num);
    //       // this.MkeessCont += Number(this.HYmess[i].unread_num);
    //       // s += Number(this.HYmess[i].unread_num);
    //       // this.Num += Number(this.HYmess[i].unread_num);
    //       // this.Mkeess({ number: this.Num });
    //     }
    //     // console.log('Shuju',this.MkeessCont);

    //     // this.Mkeess(s);
    //   });
    // },

    async infoPerfectionLL() {
      let Data = local.get("access_token");
      const { code, data } = await infoPerfection(Data);
      if (code == 0) {
        console.log("00", data);
        this.Percentage = parseInt(data);
      }
      //初始化
    },
    //相互认识点击
    async renshi(item) {
      item.show = !item.show;
      this.sendMess(item.id);
    },

    sendMess(o) {
      // let id = this.WebIM.conn.getUniqueId(); // 生成本地消息id
      let id = this.$WebIM.conn.getUniqueId(); // 生成本地消息id
      // let msg = new this.WebIM.message("txt", id); // 创建文本消息
      let msg = new this.$WebIM.message("txt", id); // 创建文本消息
      const _that = this;
      msg.set({
        // msg: "你好", // 消息内容
        msg: DB.gt(),
        to: `${o}`, // -----------------------------------------------------------------------------------------(接收消息对象（用户id) )
        chatType: "singleChat", // 设置为单聊
        success: function(id, serverMsgId) {
          console.log("send private text Success", id, serverMsgId);
          _that.$message({
            message: "打招呼发送成功",
            type: "success",
            offset: 100,
          });
          let d = {
            tid: o,
          };
          loveLove(d);
          _that.tkL();
        },
        fail: function(e) {
          _that.$message({
            message: "打招呼发送失败",
            type: "error",
            offset: 100,
          });
          console.log("Send private text error", e);
        },
      });
      // console.log(msg.body);
      // _that.WebIM.conn.send(msg.body);
      _that.$WebIM.conn.send(msg.body);
    },
    hy() {
      let rel = this.$router.resolve({
        path: "/n/yezaiMember",
      });
      window.open(rel.href, "_blank");
    },
    pao(id) {
      if (id == 1) {
        // console.log("完善资料");
        let rel = this.$router.resolve({
          path: "/n/user/_baseInfo/left",
          query: {
            id: id,
          },
        });
        window.open(rel.href, "_blank");
      } else if (id == 2) {
        // console.log("预览");
        let rel = this.$router.resolve({
          path: "/u",
          query: {
            id: this.idcard,
          },
        });
        window.open(rel.href, "_blank");
      }
    },
    async tkL() {
      const D = await subTk();
      if (D.code == 0) {
        local.SessionSet("sub_tk", D.data);
      }
    },

    //获取消息数
    async msgTata() {
      let d = {
        isJust: true,
      };
      const da = await msgTotal(d);
      if (da.code == 0) {
        this.ok = da.data;
      }
    },
    //获取参数
    async canshu() {
      this.tkL();
      this.msgTata();
      // let d = {
      //   isJust: true,
      // };
      // const da = await msgTotal(d);
      // if (da.code == 0) {
      //   this.ok = da.data;
      // }
      // 获取当前token
      let Data = local.get("access_token");
      const { code, data } = await infocur(Data);
      if (code == 0) {
        this.nickName = data.user.nickName;
        this.iSmember = data.user.isVipMember; //是否会员
        this.realAuthStatus = data.user.realAuthStatus; //是否实名认证
        this.avatar = ossL(data.user.avatar);
        this.idcard = data.user.id;
        local.set("userId", this.idcard);
        this.city = data.user.workAddrCityName;
        this.number = data.albums.length;
        this.isAvatar = data.user.isAvatar;
        this.sex = data.user.sex;
        this.login();
      }
      const L = await allAppStatus();
      if (L.code == 0) {
        // console.log(D.data);
        this.avatarAppStatus = L.data.avatarAppStatus;
      }
      //推荐人数量
      const a = await InforRecommend({
        pageNum: 1,
        pageSize: 30,
      });
      if (a.code == 0) {
        this.resultlist = a.data.records;
        this.resultlist.map(async (item) => {
          const that = this;

          if (!item.memberMateSelection.ageMax) {
            if (!item.memberMateSelection.ageMin) {
              that.memberMateSelectionAge = "";
            } else {
              that.memberMateSelectionAge =
                item.memberMateSelection.ageMin + "岁以上";
            }
          } else {
            if (!item.memberMateSelection.ageMin) {
              that.memberMateSelectionAge =
                item.memberMateSelection.ageMax + "岁以下";
            } else {
              that.memberMateSelectionAge =
                item.memberMateSelection.ageMin ==
                item.memberMateSelection.ageMax
                  ? `${item.memberMateSelection.ageMax}岁`
                  : `${item.memberMateSelection.ageMin}-${item.memberMateSelection.ageMax}岁`;
            }
          }

          if (!item.memberMateSelection.heightMax) {
            if (!item.memberMateSelection.heightMin) {
              that.memberMateSelectionHeight = "";
            } else {
              that.memberMateSelectionHeight =
                item.memberMateSelection.heightMin + "cm以上";
            }
          } else {
            if (!item.memberMateSelection.heightMin) {
              that.memberMateSelectionHeight =
                item.memberMateSelection.heightMax + "cm以下";
            } else {
              that.memberMateSelectionHeight =
                item.memberMateSelection.heightMin +
                "-" +
                item.memberMateSelection.heightMax +
                "cm";
            }
          }

          if (!item.memberMateSelection.monthIncomeMax) {
            if (!item.memberMateSelection.monthIncomeMin) {
              that.memberMateSelectionMonthIncome = "";
            } else {
              that.memberMateSelectionMonthIncome =
                item.memberMateSelection.monthIncomeMin + "元以上";
            }
          } else {
            if (!item.memberMateSelection.monthIncomeMin) {
              that.memberMateSelectionMonthIncome =
                item.memberMateSelection.monthIncomeMax + "元以下";
            } else {
              that.memberMateSelectionMonthIncome =
                item.memberMateSelection.monthIncomeMin +
                "-" +
                item.memberMateSelection.monthIncomeMax +
                "元";
            }
          }
          console.log(
            "=====",
            that.memberMateSelectionAge,
            that.memberMateSelectionHeight,
            that.memberMateSelectionMonthIncome
          );
          if (item.introduce) {
            console.log("存在");
          } else if (
            that.memberMateSelectionAge ||
            that.memberMateSelectionHeight ||
            that.memberMateSelectionMonthIncome
          ) {
            item.introduce = `我正在寻找${
              // item.memberMateSelection.workAddrProvinceName
              //   ? item.memberMateSelection.workAddrCityName
              //     ? item.memberMateSelection.workAddrProvinceName ==
              //       item.memberMateSelection.workAddrCityName
              //       ? item.memberMateSelection.workAddrProvinceName
              //       : item.memberMateSelection.workAddrProvinceName +
              //         item.memberMateSelection.workAddrCityName
              //     : item.memberMateSelection.workAddrProvinceName
              //   : item.workAddrProvinceName == item.workAddrCityName
              //   ? item.workAddrProvinceName
              //   : item.workAddrProvinceName + item.workAddrCityName
              item.memberMateSelection.workAddrProvinceName
                ? item.memberMateSelection.workAddrCityName
                  ? item.memberMateSelection.workAddrProvinceName ==
                    item.memberMateSelection.workAddrCityName
                    ? item.memberMateSelection.workAddrProvinceName
                    : item.memberMateSelection.workAddrProvinceName +
                      item.memberMateSelection.workAddrCityName
                  : item.memberMateSelection.workAddrProvinceName
                : item.workAddrProvinceName
                ? item.workAddrProvinceName == item.workAddrCityName
                  ? item.workAddrProvinceName
                  : item.workAddrProvinceName + item.workAddrCityName
                : "附近"
            }${
              that.memberMateSelectionAge
                ? ",年龄在" + that.memberMateSelectionAge
                : ""
            }${
              that.memberMateSelectionHeight
                ? ",身高在" + that.memberMateSelectionHeight
                : ""
            }${
              that.memberMateSelectionMonthIncome
                ? ",月薪" + that.memberMateSelectionMonthIncome
                : ""
            }的${item.sex == "0" ? "女性" : "男性"}`;
          } else {
            if (item.sex == "0") {
              item.introduce = `我正在寻找${
                item.workAddrProvinceName
                  ? item.workAddrCityName
                    ? item.workAddrProvinceName == item.workAddrCityName
                      ? item.workAddrProvinceName
                      : item.workAddrProvinceName + item.workAddrCityName
                    : item.workAddrProvinceName
                  : "附近"
              },年龄在${item.age - 7 >= 18 ? item.age - 7 : 18}-${
                item.age + 3 >= 99 ? 99 : item.age + 3
              }岁的${item.sex == "0" ? "女性" : "男性"}`;
            } else {
              item.introduce = `我正在寻找${
                // item.workAddrProvinceName == item.workAddrCityName
                //   ? item.workAddrProvinceName
                //   : item.workAddrProvinceName + item.workAddrCityName
                item.workAddrProvinceName
                  ? item.workAddrCityName
                    ? item.workAddrProvinceName == item.workAddrCityName
                      ? item.workAddrProvinceName
                      : item.workAddrProvinceName + item.workAddrCityName
                    : item.workAddrProvinceName
                  : "附近"
              },年龄在${item.age - 3 >= 18 ? item.age - 3 : 18}-${
                item.age + 7 >= 99 ? 99 : item.age + 7
              }岁的${item.sex == "0" ? "女性" : "男性"}`;
            }
          }
          this.arry2.push(
            Object.assign({}, item, {
              show: true,
              htImg: ossL(item.avatar),
            })
          );
        });
      }
      //删除本身
      for (let i = 0; i < this.resultlist.length; i++) {
        if (this.resultlist[i].id == this.idcard) {
          this.resultlist.splice(i, 1);
        }
      }
      this.arry2.forEach((item) => {
        this.educations.forEach((i) => {
          if (i.label == item.education) {
            item.education = i.value;
          }
        });
      });
    },
    // formatAge(ageMin, ageMax) {
    //   let minContent = "";
    //   let maxContent = "";
    //   if (ageMax == null && ageMin != null) {
    //     minContent = ageMin + "岁以上";
    //     maxContent = "";
    //   } else if (ageMax != null && ageMin == null) {
    //     maxContent = ageMax + "岁以下";
    //     minContent = "";
    //   } else if (ageMax != null || ageMin != null) {
    //     minContent = ageMin + "-";
    //     maxContent = ageMax + "岁";
    //   }
    //   return minContent + maxContent;
    // },
    format() {
      return `${this.Percentage}%`;
    },
    jump(i) {
      if (i == 1) {
        let rel = this.$router.resolve({
          path: "/n/user/_album",
        });
        window.open(rel.href, "_blank");
      } else if (i == 2) {
        let rel = this.$router.resolve({
          path: "/yezaigold",
        });
        window.open(rel.href, "_blank");
      }
    },
    //跳转资料页
    async info(item) {
      let rel = this.$router.resolve({
        path: "/u",
        query: {
          id: item.id,
        },
      });
      window.open(rel.href, "_blank");
    },
    getList() {
      this.page++;
    },
    jumpL(i) {
      if (i == 1) {
        let rel = this.$router.resolve({
          path: "/n/message/_follow",
        });
        window.open(rel.href, "_blank");
      } else if (i == 2) {
        let rel = this.$router.resolve({
          path: "/n/message",
        });
        window.open(rel.href, "_blank");
      } else if (i == 3) {
        let rel = this.$router.resolve({
          path: "/n/message/_visit",
        });
        window.open(rel.href, "_blank");
      }
    },
    jumpZ(i) {
      if (i == 1) {
        let rel = this.$router.resolve({
          path: "/n/yezaiMember",
        });
        window.open(rel.href, "_blank");
      } else if (i == 2) {
        console.log("99");
        // let rel = this.$router.resolve({
        //   path: "/yezaigold",
        // });
        // window.open(rel.href, "_blank");
      } else if (i == 3) {
        let rel = this.$router.resolve({
          path: "/directStore/home",
        });
        window.open(rel.href, "_blank");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.myyezai {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;
  background-color: #fafafa;
  justify-content: center;

  .banner {
    height: 148px;
    margin-top: 24px;
  }
  .m-mail {
    margin-top: 20px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: space-between;

    .m-mail-data {
      width: 290px;
      height: 214px;
      background: #ffffff;
      border-radius: 8px;
      box-sizing: border-box;
      border: 1px solid #ececec;
      padding: 24px;
      padding-right: 0px;
      .m-mail-data-top {
        display: flex;
        font-size: 14px;
        .m-mail-data-top-img {
          position: relative;
          width: 100px;
          height: 100px;
          cursor: pointer;
          img {
            width: 100px;
            height: 100px;
            cursor: pointer;
          }
          p {
            position: absolute;
            bottom: 0px;
            width: 100%;
            display: flex;
            height: 24px;
            align-items: center;
            justify-content: center;
            color: #ffffff;
            background-color: rgba(0, 0, 0, 0.4);
          }
        }

        .data-top-right {
          width: 130px;
          height: 100px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          .data-NT {
            display: flex;
            .data-name {
              // width: 80px;
              overflow: hidden; /*超出宽度部分的隐藏*/
              white-space: nowrap; /*文字不换行*/
              text-overflow: ellipsis;
              font-weight: bold;
              font-size: 16px;
              color: #333333;
              cursor: pointer;

              overflow: hidden;
              text-overflow: ellipsis;
              // line-height: 20px;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              display: -moz-box;
              -moz-line-clamp: 1;
              -moz-box-orient: vertical;
              word-wrap: break-word;
              word-break: break-all;
              white-space: normal;
            }
            img {
              margin: 2px 4px;
              width: 16px;
              height: 16px;
            }
          }
          .data-id {
            margin-top: 4px;
            color: #999999;
            font-size: 12px;
          }
          margin-left: 12px;
          .data-ZB {
            display: flex;
            flex-direction: column;
            position: relative;
            .progress {
              margin-top: 16px;
              margin-bottom: 20px;
              /deep/ .el-progress-bar__outer {
                width: 130px;
              }
            }
            .data-ZB-zi {
              display: flex;
              justify-content: space-around;
              .ZB-zi-bfb {
                font-size: 12px;
                color: #777777;
                cursor: pointer;
              }
              .ZB-zi-a {
                font-size: 12px;
                color: #fd686e;
                cursor: pointer;
                font-weight: bold;
              }
            }
          }
        }
      }
      .m-mail-data-bottom {
        display: flex;
        width: 250px;
        .bottom-box1,
        .bottom-box2 {
          margin-top: 22px;
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          .num {
            width: 12px;
            height: 28px;
            font-size: 20px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 28px;
          }
          .bottom-a {
            font-size: 12px;
            color: #999;
            font-weight: bold;
            span:nth-child(2) {
              color: #fd686e;
              cursor: pointer;
            }
          }
        }
        .bottom-box1 {
          border-right: 1px solid #d8d8d8;
        }
      }
    }
    .m-mail-right {
      width: 890px;
      height: 150px;
      background: #ffffff;
      border-radius: 8px;
      border: 1px solid #ececec;
      display: flex;
      color: #333333;
      font-size: 16px;
      .m-mail-eyes,
      .m-mail-emil,
      .m-mail-me {
        position: relative;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 260px;
        cursor: pointer;
        .fly {
          color: #fd686e;
        }
      }
      .hov:hover img {
        transform: scale(0.8);
      }
      .hov {
        img {
          margin-bottom: 22px;
        }
      }
      .m-mail-eyes {
        margin-left: 50px;
      }
      .m-mail-eyes::after,
      .m-mail-emil::after {
        position: absolute;
        top: 60px;
        right: 0px;
        content: "";
        width: 1px;
        height: 56px;
        background: #d8d8d8;
      }
    }
  }
  .m-details {
    margin-top: 20px;
    // margin-bottom: 138px;
    margin-bottom: 38px;
    display: flex;
    .advertisement {
      width: 300px;
      margin-right: 20px;
      .advertisement-service {
        height: 390px;
        margin-bottom: 20px;
        background: #ffffff;
        border-radius: 8px;
        border: 1px solid #ececec;
        box-sizing: border-box;
        padding: 0px 20px;
        padding-top: 16px;
        padding-bottom: 22px;
        display: flex;
        flex-direction: column;
        h3 {
          height: 22px;
          font-size: 16px;
          color: #000000;
          line-height: 22px;
        }

        ul {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          li:nth-child(1) {
            border: none;
          }
          li {
            display: flex;
            flex: 1;
            cursor: pointer;
            align-items: center;
            justify-content: space-between;
            border-top: 1px solid #ececec;
            img {
              margin-left: 8px;
            }
            h4:hover {
              color: #fd686e;
            }
            div {
              p:nth-child(1) {
                font-size: 14px;
                font-weight: 500;
                color: #333333;
                line-height: 20px;
              }
              h4 {
                margin-bottom: 4px;
              }
              p:nth-child(2) {
                width: 168px;
                font-size: 12px;
                color: #666666;
                line-height: 17px;
              }
            }
          }
        }

        background-color: #fd686e;
        background-color: #fff;
        padding-left: 20px;
        border-radius: 8px;
        border: 1px solid #ececec;
        .line {
          width: 250px;
          height: 1px;
          background: #ececec;
          margin-top: 15px;
        }
        .serve {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 22px;
          padding-top: 16px;
        }
        .content {
          display: flex;
          flex-direction: row;
          margin-top: 20px;
          img {
            cursor: pointer;
          }
          .explain {
            width: 168px;
            height: 34px;
            margin-left: 20px;
            :first-child {
              font-size: 14px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #333333;
              line-height: 20px;
            }
            :last-child {
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #777777;
              line-height: 17px;
            }
          }
        }
      }
      .advertisement-relevant {
        height: 154px;
        margin-bottom: 20px;
        height: 154px;
        background: #ffffff;
        border-radius: 8px;
        border: 1px solid #ececec;
        box-sizing: border-box;
        h3 {
          height: 50px;
          display: flex;
          font-size: 16px;
          align-items: center;
          padding-left: 20px;
          color: #000000;
        }
        .line {
          height: 1px;
          background-color: #ececec;
        }
        div {
          display: flex;
          height: 100px;
          flex-direction: column;
          justify-content: space-around;
          padding-left: 20px;
        }
      }
      .advertisement-app1 {
        height: 160px;
        margin-bottom: 20px;
        background: url("../../assets/images/index/appbg.png");
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        div {
          font-size: 26px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 37px;
          margin-top: 40px;
        }
        p {
          margin-top: 14px;
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 25px;
        }
      }
      .advertisement-app2 {
        height: 160px;
        width: 290px;
        background: url("../../assets/images/index/reverse.png");
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        div {
          width: 208px;
          height: 74px;
          font-size: 26px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 37px;
          // margin: 0 auto;
          text-align: center;
          padding-top: 8px;
        }
        p {
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #f9d02d;
          line-height: 28px;
        }
        .jc {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 202px;
          height: 30px;
          background: #3f85f0;
          border-radius: 0px 12px 0px 12px;
          margin-top: 5px;
        }
        :last-child {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 22px;
          margin-top: 6px;
        }
      }
    }
    .details-user {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin-top: -76px;
      z-index: 10;
      .user-top {
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .user-top-title {
          font-size: 16px;
          color: #333333;
          font-weight: bold;
        }
        // .top-condition {
        //   color: #fd686eff;
        //   cursor: pointer;
        // }
      }
      .user-bottom {
        width: 890px;
        // min-height: 780px;
        // min-height: 728px;
        // border: 1px solid #ecececff;
        float: left;
        background-color: #ffffff;
        border-radius: 4px;
        box-sizing: border-box;
        .user:nth-child(2n) {
          border-left: none;
        }
        .user:nth-child(1) {
          border-top: 1px solid #ecececff;
        }
        .user:nth-child(2) {
          border-top: 1px solid #ecececff;
        }
        .user {
          float: left;
          width: 445px;
          height: 182px;
          display: flex;
          flex-direction: row;
          box-sizing: border-box;
          border: 1px solid #ecececff;
          border-top: none;
          cursor: pointer;
          .image {
            width: 150px;
            height: 150px;
            margin: 12px;
            img {
              width: 150px;
              height: 150px;
              //*time: 11.21
              object-fit: contain;
              background-color: #000;
            }
            // .img-object {
            //   object-fit: cover;
            //   object-position: 50% 20%;
            // }
          }
          .missing {
            width: 246px;
            height: 60px;
            margin-top: 10px;
            // box-sizing: border-box;
            .missing-name {
              display: flex;
              align-items: center;
              .missing1 {
                font-size: 16px;
                font-weight: bold;
                color: #666666;
                line-height: 26px;
                // flex: 1;
                // width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                // line-height: 20px;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                display: -moz-box;
                -moz-line-clamp: 1;
                -moz-box-orient: vertical;
                word-wrap: break-word;
                word-break: break-all;
                white-space: normal;
              }
              img {
                width: 16px;
                height: 16px;
                vertical-align: middle;
                margin-left: 5px;
                // flex: 1;
              }
            }

            .personal {
              font-size: 14px;
              // font-weight: bold;
              color: #666666;
              line-height: 34px;
            }
            .intr {
              height: 40px;
              display: flex;
              align-items: center;
              font-size: 14px;
              color: #666666;
              line-height: 20px;
              .ni {
                overflow: hidden;
                text-overflow: ellipsis;
                line-height: 20px;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                display: -moz-box;
                -moz-line-clamp: 2;
                -moz-box-orient: vertical;
                word-wrap: break-word;
                word-break: break-all;
                white-space: normal;
              }
            }
            .btn:hover {
              background: linear-gradient(90deg, #ffa1a7 0%, #fd686e 100%);
              color: #fff;
              border: none;
            }
            .btn:hover .btn-aa {
              display: block;
            }
            .btn:hover .btn-aaL {
              display: none;
            }
            .btn-aa {
              display: none;
            }
            .btn {
              width: 226px;
              margin-top: 14px;
              border-radius: 4px;
              // border: 1px solid #fd686e;
              border: 1px solid rgba(255, 199, 201, 1);
              text-align: center;
              line-height: 36px;
              color: #fd686e;
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                margin-left: 6px;
              }
            }
            .btn-a {
              width: 226px;
              margin-top: 14px;
              line-height: 36px;
              border-radius: 4px;
              background: #dbdbdb;
              color: #ffffffff;
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                margin-left: 6px;
              }
            }
          }
        }
      }
      //选择
      // .cityShow {
      //   height: 56px;
      //   display: flex;
      //   // background-color: pink;
      //   // justify-content: flex-end;
      //   // flex-direction: row;
      //   align-items: center;
      //   .city-change {
      //     cursor: pointer;
      //     color: #ffffff;
      //     .information {
      //       display: flex;
      //       flex-direction: row;
      //       width: 890px;
      //       height: 40px;
      //       border-radius: 4px;
      //       border: 1px solid #ececec;
      //       // .city {
      //       //   width: 180px;
      //       //   height: 200px;
      //       // }
      //       .city-btn {
      //         line-height: 34px;
      //         padding: 4px;
      //         font-size: 14px;
      //         font-family: PingFangSC-Medium, PingFang SC;
      //         font-weight: 500;
      //         color: #fd686e;
      //       }
      //       .cancel {
      //         color: #777;
      //       }
      //     }
      //   }
      // }
    }
  }
  .m-top {
    width: 100%;
    height: 223px;
    background: url("../../assets/images/slices/wen.png");
    display: flex;
    justify-content: center;
    align-items: center;
    .m-top-title {
      width: 1200px;
      height: 90px;
      background-color: #fff;
      border: 1px solid #ebebeb;
      display: flex;
      align-items: center;
      justify-content: center;
      .m-top-title_btn {
        cursor: pointer;
        width: 160px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fd686e;
        color: #fff;
        font-size: 14px;
        border-radius: 6px;
      }
      p {
        font-size: 16px;
        color: #333;
        margin-right: 30px;
      }
    }
  }

  .m-choice {
    display: flex;
    justify-content: flex-end;
    margin: 22px 0 82px 0;
    .choice-box {
      width: 878px;
      height: 38px;
      background-color: red;
    }
  }
}

.progress /deep/ .el-progress__text {
  // position: absolute;
  // top: 30px;
  // left: -10px;
  // font-size: 12px !important;
  // color: #777777;
  display: none;
}
.progress /deep/ .el-progress-bar__inner {
  background-color: #fd686e;
  height: 6px !important;
}
/deep/ .el-progress-bar__outer {
  height: 6px !important;
}
</style>
